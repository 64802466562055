@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Humane';
    src: url('./fonts/Humane-VF.ttf');
}